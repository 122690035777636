import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import './AddLoanWithoutInterest.scss';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Sidebar from '../../sidebar/sidebar';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';        
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import AddLoan from '../AddLoans/AddLoan';
import {BaseUrl} from "../../../Environment";
import { withNamespaces } from 'react-i18next';
import { Select } from '@mui/material';
import { getSecureItem } from '../../../utilsFunctions';

const axios = require('axios')
const EntryLoanType = [
  {
    value: 'Direct Method',
    label: 'Direct Method',
  },
  {
    value: 'EMI Method',
    label: 'EMI Method',
  }
]
class AddLoanWithoutInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whenNotPaid: "",
      autoUpdateNPD: "",
      file: '',
      imagePreviewUrl: '',
      changeColorname: '1px solid #D4D4D5',
      changeColoremail: '1px solid #D4D4D5',
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: '1px solid #D4D4D5',
      // changeColoremail: '1px solid #D4D4D5',
      changeColorcategory: '1px solid #D4D4D5',
      category: "collector",
      collectionagent: "",
      SelectedDate: new Date(),
      brands: [{ id: 1, name: "One Time" },{ id: 2, name: "Monthly" }, { id: 3, name: "Weekly" }, { id: 4, name: "Daily" }],
      loantenure: "",
      distamount: "",
      installment: "",
      totalAmount: "",
      processFee: 0,
      tenure: "",
      OpeningBalance: "",
      ClosingBalance: "",
      laontype: "",
      LaonType: [],
      method: "",
      penalty: "",
      customerId: "",
      userId: "",
      Collectors: [],
      collectionArr: [],
      scheduleArr: [],
      userdetails: [],
      choosecustmr: "",
      rows: [],
      choosecustomer: "",
      allCustomer: [],
      path: "",
      afterSelect: "",
      loanTypeId: "",
      amountfrom: "0",
      amountto: "0",
      daysfrom: "0",
      daysto: "0",
      onetimefrom: "0",
      onetimeto: "0",
      weeksfrom: "0",
      weeksto: "0",
      monthsfrom: "0",
      monthsto: "0",
      days: "none",
      weeks: "none",
      months: "none",
      onetime:"",
      Entrylaontype: "Direct Method",
      calInterest:"none",
      InterestButtonAction:"none",
      ButtonAction:"",
      isdisabled:true,
      searchdone:false
    };
    this.nameBox = this.nameBox.bind(this)
    this.numberBox = this.numberBox.bind(this)
    this.annualBox1 = this.annualBox1.bind(this)
    this.annualBox = this.annualBox.bind(this)
    this.emailBox = this.emailBox.bind(this)
    this.emailBox1 = this.emailBox1.bind(this)
    this.emailBox2 = this.emailBox2.bind(this)
    this.emailBox3 = this.emailBox3.bind(this)
    this.emailBox4 = this.emailBox4.bind(this)
    this.emailBox5 = this.emailBox5.bind(this)
    this.calculationwithnointerest = this.calculationwithnointerest.bind(this)
    this.AllLoanTypeList = this.AllLoanTypeList.bind(this)
    this.allCollectionagent = this.allCollectionagent.bind(this)
  }

  searchSubmit = e => {
    this.setState({isdisabled:true,rows:[]});
    if (e.target.value === "One Time") {
      this.setState({
        tenure: "onetime",
        onetime:"",
        days: "none",
        weeks: "none",
        months: "none",
      })
    }
    else if (e.target.value === "Monthly") {
      this.setState({
        tenure: "monthly",
        onetime:"none",
        days: "none",
        weeks: "none",
        months: "",
      })
    } else if (e.target.value === "Weekly") {
      this.setState({
        tenure: "weekly",
        onetime:"none",
        days: "none",
        weeks: "",
        months: "none",
      })
    } else {
      this.setState({
        tenure: "daily",
        onetime:"none",
        days: "",
        weeks: "none",
        months: "none",
      })
    }
  };
  allCollectionagent() {
    let loggedinUser = getSecureItem("loggedinUserCompany")

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ getSecureItem("token")
    }
    axios.get(BaseUrl + '/user/getAllCollectors?companyId=' + loggedinUser, {
      headers: headers,
    }).then(resp => {
      if (resp.status === 200 || resp.status === 304) {
        this.setState({
          Collectors: resp.data?.filter((item) => item?.isActive === true),

        })
      } else {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    }).catch(err => {
      if (err.request.status !== 200) {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    })
  }

  searchlist = (e) => {
    let loggedinUser = getSecureItem("loggedinUserCompany")
    this.setState({
      choosecustomer: e.target.value,      
      afterSelect: "",
      isdisabled:true,
      rows:[]
    })

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ getSecureItem("token")
    }
    // BaseUrl + '/customer/getVerifiedCustomers?name='
    axios.get(BaseUrl + '/customer/getVerifiedCustomers?name=' + e.target.value + '&companyId=' + loggedinUser, {
      headers: headers,
    }).then(resp => {
      if (resp.status === 200) {
        this.setState({
          allCustomer: [],
        });
        setTimeout(() => {
          this.setState({
            allCustomer: resp.data,
          })
          if(this.state.allCustomer.length <= 0)
          {
            this.setState({
              choosecustomer:"",
            })
          }
        }, 500);
      } else {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    }).catch(err => {
      if (err.request.status !== 200) {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    })
  }
  selectName(selectedData) {
    this.setState({
      choosecustomer: selectedData.cust_id ? `${selectedData.firstName} ${selectedData.lastName} (${selectedData.cust_id})` : `${selectedData.firstName} ${selectedData.lastName}`,
      customerId: selectedData.customerId,
      afterSelect: "none",
      isdisabled:true,
      rows:[]
    })

  }

  componentDidMount() {
    if (this.props.location.params) {
      if (this.props.location.params.loanType) {
        this.setState({
          // customerId: this.props.location.params.customerId,
          // choosecustomer: this.props.location.params.firstName,
          loanTypeId:this.props.location.params.loanTypeId,
          laontype: this.props.location.params.loanType,
          amountfrom: this.props.location.params.loanAmtRangeFrom,
          amountto: this.props.location.params.loanAmtRangeTo,
          onetimefrom:1,
          onetimeto:1,
          daysfrom: this.props.location.params.tenureDaysFrom,
          daysto: this.props.location.params.tenureDaysTo,
          weeksfrom: this.props.location.params.tenureWeeksFrom,
          weeksto: this.props.location.params.tenureWeeksTo,
          monthsfrom: this.props.location.params.tenureMonthsFrom,
          monthsto: this.props.location.params.tenureMonthsTo,
          path: "Loan Type",
          rows:[],
          collectionagent:"",
          searchdone:false
          // choosecustmr:"none"

        })
      } else if (this.props.location.params.customerId) {
        this.setState({
          customerId: this.props.location.params.customerId,
          choosecustomer: this.props.location.params?.cust_id ? `${this.props.location.params?.firstName} ${this.props.location.params?.lastName} (${this.props.location.params?.cust_id})` : `${this.props.location.params?.firstName} ${this.props.location.params?.lastName}`,
          laontype: this.props.location.params.loanType,
          path: "New Customer",
          rows:[],
          collectionagent:"",
          searchdone:false

          // choosecustmr:"none"
        })
      }
    } else {
      this.setState({
        path: "Loans",
        collectionagent:"",
        rows:[],
        searchdone:false
      })
    }
    this.setState({
      tenure: "onetime",
      userId: getSecureItem('userid'),
      rows:[],
      collectionagent:"",
      searchdone:false
    })
    this.AllLoanTypeList();
    this.allCollectionagent();
  }
  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }


  handleChange = event => {
    this.setState({ category: event.target.value,
      isdisabled:true,rows:[]});
    // this.setState({ vehiclemodel: event.target.value })
  };
  nameBox() {
    this.setState({
      changeColorname: "1px solid #00D95E",
      changeColornumber: "1px solid #D4D4D5",
      changeColoremail: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5"
    })
  }
  numberBox() {
    this.setState({
      changeColornumber: "1px solid #00D95E",
      changeColorname: "1px solid #D4D4D5",
      changeColoremail: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5"
    })
  }
  emailBox() {
    this.setState({
      changeColoremail: "1px solid #00D95E",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  emailBox1() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #00D95E",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  emailBox2() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #00D95E",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  emailBox3() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #00D95E",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  emailBox4() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #00D95E",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  emailBox5() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #00D95E",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #D4D4D5'
    })
  }
  annualBox() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #00D95E',
      annual1: '1px solid #D4D4D5'
    })
  }
  annualBox1() {
    this.setState({
      changeColoremail: "1px solid #D4D4D5",
      changeColoremail1: "1px solid #D4D4D5",
      changeColoremail2: "1px solid #D4D4D5",
      changeColoremail3: "1px solid #D4D4D5",
      changeColoremail4: "1px solid #D4D4D5",
      changeColoremail5: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
      changeColorcategory: "1px solid #D4D4D5",
      annual: '1px solid #D4D4D5',
      annual1: '1px solid #00D95E'
    })
  }
  categoryBox() {
    this.setState({
      changeColorcategory: "1px solid #00D95E",
      changeColoremail: "1px solid #D4D4D5",
      changeColornumber: "1px solid #D4D4D5",
      changeColorname: "1px solid #D4D4D5",
    })
  }
  backLinkAction() {
    // this.props.history.push('/allnewcustomers')
    window.history.back()
  }
  handleDateChange = date => {
    this.setState({
      SelectedDate: date,
      isdisabled:true,
      rows:[]
    })
  };


  handleInputChange = event => {
    this.setState({
      amountvalues: event.target.value === '' ? '' : Number(event.target.value),
      isdisabled:true,
      rows:[]
    })
    // setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  handleSliderChange = (event, newValue) => {
    this.setState({
      amountvalues: newValue,
      isdisabled:true,
      rows:[]
    })
    
    // setValue(newValue);
  };

  handleSlidertenureChange = (event, newValue) => {
    this.setState({
      tenurevalues: newValue,
      isdisabled:true,
      rows:[]
    })

  }
  handleInputtenureChange = event => {
    this.setState({
      tenurevalues: event.target.value === '' ? '' : Number(event.target.value),
      isdisabled:true,
      rows:[]
    })

  }
  handleInputinterestChange = event => {
    this.setState({
      interestvalues: event.target.value === '' ? '' : Number(event.target.value),
      isdisabled:true,
      rows:[]
    })

  }
  handleSliderinterestChange = (event, newValue) => {
    this.setState({
      interestvalues: newValue,
      isdisabled:true,
      rows:[]
    })
  }
  AllLoanTypeList() {
    
const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer '+ getSecureItem("token")
}
    let loggedinUser = getSecureItem("loggedinUserCompany")
    axios.get(BaseUrl + '/loanType/getAllLoanTypes?loanType=&companyId=' + loggedinUser, {
      headers: headers,
    }).then(resp => {
      if (resp.status === 200) {
        this.setState({
          LaonType: resp.data,
        })
      } else {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    }).catch(err => {
      if (err.request.status !== 200) {
        toast.error("Please try again!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    })
  }
  betweenRange = () => {
    if(this.state.choosecustomer && this.state.customerId != ''){
    if ((this.state.distamount >= parseInt(this.state.amountfrom)) && (this.state.distamount <= parseInt(this.state.amountto))) {
      if (this.state.tenure === "monthly") {
        if (this.state.loantenure >= this.state.monthsfrom && this.state.loantenure <= this.state.monthsto) {
          if(this.state.whenNotPaid == ""){
            toast.error("Please select When Not Paid", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else if (this.state.whenNotPaid == "No" && this.state.autoUpdateNPD == ""){
            toast.error("Please select Auto Update Non Paid Dues(EOD)", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else {
            this.calculationwithnointerest()
          }
        } else {
          toast.error("Loan Duration must be in range", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      } else if (this.state.tenure === "weekly") {
        if (this.state.loantenure >= this.state.weeksfrom && this.state.loantenure <= this.state.weeksto) {
          this.calculationwithnointerest()
        } else {
          toast.error("Loan Duration must be in range", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      } else if (this.state.tenure === "onetime") {
        if (this.state.loantenure === "1") {
          this.calculationwithnointerest()
        } else {
          console.log("Entered one time")
          toast.error("Loan Duration must be in range", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      }
      else if (this.state.tenure === "daily") {
        if (this.state.loantenure >= this.state.daysfrom && this.state.loantenure <= this.state.daysto) {
          this.calculationwithnointerest()
        } else {
          toast.error("Loan Duration must be in range", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      }
    } else {
      toast.error("Loan Amount must be in range", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
  }
  else{
      toast.error("Choose Borrower to Assign Loan!!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  }
}

  calculationwithnointerest() {
    let loggedinUser = getSecureItem("loggedinUser")
    this.state.userdetails = {
      "loanTypeId": this.state.loanTypeId,
      "method": "Collection method",
      "penalty": parseInt(this.state.penalty),
      "loanAmount": parseInt(this.state.distamount),
      "loanTenureType": this.state.tenure,
      "loanTenure": parseInt(this.state.loantenure),
      "collectionAmount": parseInt(this.state.installment),
      "nextDueDate":this.state.SelectedDate,
      "startDate": this.state.SelectedDate,
      "totalAmountToCollect": this.state.totalAmount,
      "processFee": parseInt(this.state.processFee),
      "Accumulate": this.state.whenNotPaid,
      "autoUpdate": this.state.autoUpdateNPD,
      "createdByUserId": parseInt(this.state.userId),
      "updatedByUserId": parseInt(this.state.userId),
      "customerId": this.state.customerId,
      loanStatus: "Open",
      "createdByUserId": getSecureItem("userid"),
      "updatedByUserId": getSecureItem("userid"),
      "companyId": loggedinUser.companyId
    }    
const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer '+ getSecureItem("token")
}
    axios.post(BaseUrl + '/loan/calculate', this.state.userdetails, {
      headers: headers,
    }).then(resp => {
      this.state.scheduleArr = resp.data.map((item) => ({
        ...item,
        openingBalance: item.openingBalance,
        scheduledDate: item.scheduledDate,
        loanTenure: parseInt(this.state.loantenure),
        closingBalance: item.closingBalance,
        collectionAmount: item.collectionAmount,
        // collectorId: this.state.collectionagent,
        createdByUserId: parseInt(this.state.userId),
        updatedByUserId: parseInt(this.state.userId),
        customerId: this.state.customerId,
        "companyId": loggedinUser.companyId
      }))

      this.state.collectionArr = resp.data.map((item, index) => (
        {
          dueDate: item.scheduledDate,
          nextSchedule: resp.data.length === (index + 1) ? resp.data[index].scheduledDate : resp.data[(index + 1)].scheduledDate,
          Installment: item.collectionAmount,
          collectionAmount: item.collectionAmount,
          openingBalance: item.openingBalance,
          closingBalance: item.closingBalance,
          collectionStatus: null,
          PenaltyApplied: null,
          // openingBalance: item.openingBalance,
          // closingBalance: item.closingBalance,

          // collectorId: this.state.collectionagent,
          createdByUserId: parseInt(this.state.userId),
          updatedByUserId: parseInt(this.state.userId),
          customerId: this.state.customerId ? this.state.customerId : '',
          "companyId": loggedinUser.companyId

        }
      ))
      if (resp.request.status === 200) {
        this.setState({
          rows: this.state.scheduleArr,
          isdisabled:false,
        })
      } else {

      }
    }).catch(err => {
      if (err.response.status === 400)
      {
      toast.error("Loan Repayment Amount is less than Loan Amount!!!,"+"\n"+"Please enter correct data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,  
      });
    }
    else
    {
      toast.error("Please try again!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      }); 
    }
    })
    this.setState({
      totalAmount: this.state.totalAmount,
      isdisabled:false
    })
  }
  postLoannoInterest() {
    if(this.state.choosecustomer && this.state.collectionagent)
    {
    this.state.userdetails.loanSchedules = this.state.scheduleArr;
    this.state.userdetails.loancollections = this.state.collectionArr;
    this.state.userdetails.collectorId = this.state.collectionagent;
    this.state.userdetails.loanPaymentStatus = "Due";
    this.state.userdetails.processFee = this.state.processFee;
    this.state.userdetails.Accumulate = this.state.whenNotPaid;
    this.state.userdetails.autoUpdate = this.state.autoUpdateNPD;
    this.state.userdetails.loancollections.forEach((item, index) => {
    this.state.userdetails.loanSchedules[index].collectorId = this.state.collectionagent;
    this.state.userdetails.loancollections[index].collectorId = this.state.collectionagent;
    })

    const LoanSettings = getSecureItem("LoanSettings");
    if(LoanSettings?.autoApprove){
      this.state.userdetails.approvalDate = new Date();
      this.state.userdetails.disbursedDate = new Date();
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ getSecureItem("token")
    }
    axios.post(BaseUrl + '/loan/postLoan', this.state.userdetails, {
      headers: headers,
    }).then(res => {
      if (res.request.status === 200) {
        // {this.state.choosecustomer}
        let custname = this.state.choosecustomer
        let laontype = this.state.laontype

          if(res.data?.loanId){
            toast.success(laontype + " " + "Assigned Successfully to" + " " + custname, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });

            const LoanSettings = getSecureItem("LoanSettings");
            if(LoanSettings?.autoApprove){
              this.props.history.push("/customer");
            } else {
              this.props.history.push("/loandisbursement");
            }
          } else {
            // Alert.alert("Warning", res.data?.message)
            toast.error(res.data?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
            this.props.history.push("/customer");

          }
      }
    })
  }
  else{
    if(this.state.collectionagent)
    {
    toast.error("Choose Borrower to Assign Loan!!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    })
  }
  else
  {
    toast.error("Assign to a Collection Agent!!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    })
  }
  }
  }
  cancel() {
    //this.props.history.push("/")
    window.history.back()
  }
  collectionangentChange(event) {
    this.setState({ collectionagent: event.target.value });
  }
  clear() {
    const { brands } = this.state;
    console.log({brands});
    this.setState({
      SelectedDate: new Date().toLocaleString(),
      brands: [{ id: 1, name: "One Time" },{ id: 2, name: "Monthly" }, { id: 3, name: "Weekly" }, { id: 4, name: "Daily" }],
      rows: [],
      totalAmount: "",
      loantenure: "",
      installment: "",
      distamount: "",
      laontype: "",
      penalty: "",
      choosecustomer:"",
      amountfrom: "0",
      amountto: "0",
      daysfrom: "0",
      daysto: "0",
      weeksfrom: "0",
      weeksto: "0",
      monthsfrom: "0",
      monthsto: "0",
      Entrylaontype: "Direct Method",
      collectionArr: [],
      scheduleArr: [],
      collectionagent:"",
      tenure: "onetime",
      //tenure: "",
      days: "none",
      weeks: "none",
      months: "none",
      onetime:"",
      userId: getSecureItem('userid'),
      isdisabled:true,
      searchdone:false
    })
  }
  checking() {
    this.setState({ afterSelect: "none" })
  }
  LoanChange = (event) => {
    this.setState({
      laontype: event.target.value,
      isdisabled:true,
      rows:[]
    })
    this.state.LaonType.map(item => {
      if (event.target.value === item.loanType) {
        this.setState({
          loanTypeId: item.loanTypeId,
          amountfrom: item.loanAmtRangeFrom,
          amountto: item.loanAmtRangeTo,
          onetimefrom:1,
          onetimeto:1,
          daysfrom: item.tenureDaysFrom,
          daysto: item.tenureDaysTo,
          weeksfrom: item.tenureWeeksFrom,
          weeksto: item.tenureWeeksTo,
          monthsfrom: item.tenureMonthsFrom,
          monthsto: item.tenureMonthsTo,
        })
      }

    })

  }
  TableData=(rows,collectionagent,isdisabled)=>{
    console.log("parent row",rows)
    this.setState({
      rows:rows,
      collectionagent:collectionagent,
      isdisabled:isdisabled
    })
  }
  // componentWillReceiveProps=(nextProps)=>{
  //   console.log("nextProps",nextProps)
  //   this.setState({
  //     rows:nextProps.rows
  //   })
  // }
  EntryLoanTypeChange = (event) => {
    this.setState({
      Entrylaontype: event.target.value,
      isdisabled:true,
      rows:[]
    })
    if(event.target.value !== "Direct Method"){
      this.setState({
        calInterest:"",
        InterestButtonAction:"",
        ButtonAction:"none"
      })
    } else {
      this.setState({
        calInterest:"none",
        InterestButtonAction:"none",
        ButtonAction:""
      })
    }
  }
  postLoanInterest=()=>{
    this.child.postLoan() 
  }
  render() {
    let countryCurrency = getSecureItem("companyCountry")
    let countryCurrencySymbol = countryCurrency?countryCurrency.symbol:"INR";    
    const { t } = this.props;

  

    function checkLoanTenure(onetimefrom, onetimeto, daysfrom, daysto, weeksfrom, weeksto, monthsfrom, monthsto, loantenure, tenure) {
      // Convert string values to numbers
      onetimefrom = parseFloat(onetimefrom);
      onetimeto = parseFloat(onetimeto);
      daysfrom = parseFloat(daysfrom);
      daysto = parseFloat(daysto);
      weeksfrom = parseFloat(weeksfrom);
      weeksto = parseFloat(weeksto);
      monthsfrom = parseFloat(monthsfrom);
      monthsto = parseFloat(monthsto);
      loantenure = parseFloat(loantenure);
  
      if (tenure == "onetime") {
        if(loantenure >= onetimefrom && loantenure <= onetimeto){
          return "none";
        } else {
          return "block";
        }
      } else if (tenure == "daily") {
        if(loantenure >= daysfrom && loantenure <= daysto){
          return "none";
        } else {
          return "block";
        }
      } else if (tenure == "weekly") {
        if(loantenure >= weeksfrom && loantenure <= weeksto){
          return "none";
        } else {
          return "block";
        }
      } else if (tenure == "monthly") {
        if(loantenure >= monthsfrom && loantenure <= monthsto){
          return "none";
        } else {
          return "block";
        }
      }
    }

    function checkAmountRange(amountfrom, amountto, amount) {
      // Convert string values to numbers
      amountfrom = parseFloat(amountfrom);
      amountto = parseFloat(amountto);
  

        if(amount >= amountfrom && amount <= amountto){
          return "none";
        } else {
          return "block";
        }
    }

    function returnInstallment(totalAmount, loantenure) {
      totalAmount = parseFloat(totalAmount);
      loantenure = parseFloat(loantenure);

      return Math.round(totalAmount/loantenure);
    }

    const {onetime, days, weeks, months, onetimefrom, onetimeto, daysfrom, daysto, weeksfrom, weeksto, monthsfrom, monthsto, tenure, loantenure, amountfrom, amountto, distamount, totalAmount, whenNotPaid, autoUpdateNPD} = this.state;

    toast.configure({});
    const { brands,isdisabled,searchdone} = this.state;    

    return (
      <div>
        <Sidebar />
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <div>
            <h3 className="Fonts headFontSize" style={{ marginLeft: '45px' }}><span className="backLink" onClick={this.backLinkAction.bind(this)}>{this.state.path}</span> / Add Loan</h3>
          </div>
        </div>

        <div className="addloancomponent">
          {/* <Grid item xs={10} style={{display:"none"}}><div></div></Grid> */}
          <div style={{marginLeft:'auto'}} className="xyz">
          <div className="LaontypeEntryDiv">
              <TextField
                id="standard-select-currency"
                select
                value={this.state.Entrylaontype || ""}
                onChange={this.EntryLoanTypeChange}
                className="LaontypeEntry"
              >
                {EntryLoanType.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
          </div>
          </div>
          {this.state.Entrylaontype === "Direct Method" ? (
            <div>
              <Card className="cardDiv" onClick={this.checking.bind(this)}>
                <div className="previewComponent">
                  <form onSubmit={(e) => this._handleSubmit(e)}>
                    <div className="rowColumn">
                      <div className="rowColumn50100">
                        <div className="textFieldStyle Loan">
                          <h6 className="InputLabel Fonts SizeFont">{t('AddLoanType.Type')}</h6>
                          {/* <Input className="textBox" style={{ height: '35px', border: this.state.changeColorname }} onClick={this.nameBox} onChange={(event) => this.setState({ name: event.target.value })} /> */}
                          <TextField
                            id="standard-select-currency"
                            select
                            className="incomefield"
                            value={this.state.laontype || ""}
                            style={{ border: this.state.annual, width: "80%", height: "35px", border: "1px solid rgb(212, 212, 213)", borderRadius: "5px" }}
                            onClick={this.annualBox}
                            onChange={this.LoanChange}
                          >
                            {/* (event) => this.setState({ laontype: event.target.value }) */}
                            {this.state.LaonType.map(option => (
                              <MenuItem key={option.loanTypeId} value={option.loanType}>
                                {option.loanType}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="textFieldStyle">
                          <h6 className="InputLabel Fonts SizeFont">{t('AddLoan.method')}</h6>
                          <Input className="textBox" value="Collection method" style={{ height: '35px', border: this.state.changeColoremail }} onClick={this.emailBox} onChange={(event) => this.setState({ method: event.target.value })} disabled />
                        </div>

                        <div className="textFieldStyle">
                          <h6 className="InputLabel Fonts SizeFont">{t('AddLoan.penality')}</h6>
                          <div className="textBox" style={{ height: '35px', border: this.state.changeColoremail1, display: "flex" }}><span className="aboutfield">{countryCurrencySymbol}</span>
                            <Input type="number" value={this.state.penalty || ""} onClick={this.emailBox1} onChange={(event) => this.setState({ penalty: event.target.value,isdisabled:true,rows:[] })} />
                          </div>
                        </div>
                        <div className="textFieldStyle" style={{ width: '95%' }}>
                          <h6 className="InputLabel Fonts SizeFont" >{t('AddLoan.date')}</h6>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker disableToolbar variant="inline" format="dd/MM/yyyy" margin="normal" id="date-picker-inline" value={this.state.SelectedDate} onChange={this.handleDateChange.bind(this)} KeyboardButtonProps={{ 'aria-label': 'change date' }} />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="textFieldStyle Loan">
                          <h6 className="InputLabel Fonts SizeFont">{"When Not Paid"}</h6>
                          <Select
                            className="incomefield"
                            value={whenNotPaid}
                            style={{ border: this.state.annual, width: "80%", height: "35px", border: "1px solid rgb(212, 212, 213)", borderRadius: "5px" }}
                            onClick={this.annualBox}
                            onChange={(e) => this.setState({ whenNotPaid: e.target.value, autoUpdateNPD: "" })}
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              Please Select
                            </MenuItem>
                            <MenuItem value="Yes">
                              Add Non payment Amount to the next Installment Amount
                            </MenuItem>
                            <MenuItem value="No">
                              Increase the tenure of the Loan Repayment Schedule
                            </MenuItem>
                          </Select>
                        </div>
                      </div>

                      <div className="rowColumn50100">
                        <div className="textFieldStyle">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "20%",
                            }}
                          >
                            <h6 className="InputLabel Fonts SizeFont">
                              {t("AddLoan.tenure")}
                            </h6>
                            <div className="rowColumn">
                              <select
                                onChange={this.searchSubmit}
                                className="monthlySelect"
                              >
                                {brands.map((brand) => (
                                  <option value={brand.name} key={brand.name}>
                                    {brand.name}
                                  </option>
                                ))}
                              </select>
                              <span
                                className="InputLabel Fonts linkFontSize"
                                style={{
                                  paddingLeft: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {this.state.tenure + " " + "range is:" + " "}

                                <span style={{ display: this.state.onetime }}>
                                  {this.state.onetimefrom +
                                    "-" +
                                    this.state.onetimeto}
                                </span>
                                <span style={{ display: this.state.days }}>
                                  {" "}
                                  {this.state.daysfrom +
                                    "-" +
                                    this.state.daysto}
                                </span>
                                <span style={{ display: this.state.months }}>
                                  {" "}
                                  {this.state.monthsfrom +
                                    "-" +
                                    this.state.monthsto}
                                </span>
                                <span style={{ display: this.state.weeks }}>
                                  {" "}
                                  {this.state.weeksfrom +
                                    "-" +
                                    this.state.weeksto}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              height: "35px",
                              border: this.state.changeColoremail2,
                              display: "flex",
                            }}
                            className="textBox"
                          >
                            <Input
                              type="number"
                              onClick={this.emailBox2}
                              value={loantenure || ""}
                              onChange={(event) => {
                                this.setState({
                                  loantenure: event.target.value,
                                  isdisabled: true,
                                  rows: [],
                                  installment: returnInstallment(totalAmount, event.target.value)
                                })
                                checkLoanTenure(onetimefrom, onetimeto, daysfrom, daysto, weeksfrom, weeksto, monthsfrom, monthsto, loantenure, tenure);
                              }}
                            />
                            <span className="aboutfield">
                              {this.state.tenure}
                            </span>
                          </div>
                          <span style={{display: loantenure != "" ? checkLoanTenure(onetimefrom, onetimeto, daysfrom, daysto, weeksfrom, weeksto, monthsfrom, monthsto, loantenure, tenure) : "none"}} className="InputLabel Fonts SizeFont">Range is <span style={{ display: onetime }}>
                                  {onetimefrom +
                                    "-" +
                                    onetimeto}
                                </span>
                                <span style={{ display: days }}>
                                  {" "}
                                  {daysfrom +
                                    "-" +
                                    daysto}
                                </span>
                                <span style={{ display: months }}>
                                  {" "}
                                  {monthsfrom +
                                    "-" +
                                    monthsto}
                                </span>
                                <span style={{ display: weeks }}>
                                  {" "}
                                  {weeksfrom +
                                    "-" +
                                    weeksto}
                                </span> only. <a style={{textDecoration: "none", color: "blue", borderRadius: "5px", cursor: "pointer"}} onClick={() => this.props.history.push("/loantype")}>Click here</a> to edit loan duration range.</span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}} className="textFieldStyle">
                          <div>
                          <h6 className="InputLabel Fonts SizeFont">{"Loan Amount Given"}</h6>
                          <div style={{ height: '35px', border: this.state.changeColoremail3, display: "flex" }} className="textBox"><span className="aboutfield">{countryCurrencySymbol}</span>
                            <Input type="number" onClick={this.emailBox3} value={this.state.distamount || ""} onChange={(event) => {
                              this.setState({ distamount: event.target.value,isdisabled:true,rows:[] });
                              checkAmountRange(amountfrom, amountto, distamount);
                              }} 
                            />
                          </div>
                          </div>
                          <div style={{marginLeft: "7%"}}>
                          <h6 className="InputLabel Fonts SizeFont">{"Processing Fee"}</h6>
                          <div style={{ height: '35px', border: this.state.changeColoremail3, display: "flex" }} className="textBox"><span className="aboutfield">{countryCurrencySymbol}</span>
                            <Input type="number" onClick={this.emailBox3} value={this.state.processFee || ""} onChange={(event) => {
                              this.setState({ processFee: event.target.value,isdisabled:true,rows:[] });
                              }} 
                            />
                          </div>
                          </div>
                          {/* <span className="InputLabel Fonts linkFontSize" style={{ paddingLeft: "10px" }}> NOTE: {" " + "Amount Range is:" + " " + this.state.amountfrom + "-" + this.state.amountto}</span> */}
                          
                        </div>
                        <span style={{display: distamount != "" ? checkAmountRange(amountfrom, amountto, distamount) : "none"}} className="InputLabel Fonts SizeFont">Range is {this.state.amountfrom + " - " + this.state.amountto} only. <a style={{textDecoration: "none", color: "blue", borderRadius: "5px", cursor: "pointer"}} onClick={() => this.props.history.push("/loantype")}>Click here</a> to edit loan amount range.</span>
                        <div style={{display: "flex", flexDirection: "row"}} className="textFieldStyle">
                          <div>
                          <h6 className="InputLabel Fonts SizeFont">{"Loan Amount to Collected"}</h6>
                          <div style={{ height: '35px', border: this.state.changeColoremail3, display: "flex" }} className="textBox"><span className="aboutfield">{countryCurrencySymbol}</span>
                            <Input type="number" onClick={this.emailBox3} value={this.state.totalAmount || ""} onChange={(event) => {
                              this.setState({ totalAmount : event.target.value,isdisabled:true,rows:[], installment: returnInstallment(event.target.value, loantenure) });
                              }} 
                            />
                          </div>
                          </div>
                          <div style={{marginLeft: "7%"}}>
                            <h6 className="InputLabel Fonts SizeFont">{t('AddLoan.instal')}</h6>
                            <div style={{ height: '35px', border: this.state.changeColoremail4, display: "flex" }} className="textBox"><span className="aboutfield">{countryCurrencySymbol}</span>
                              <Input type="number" onClick={this.emailBox4} value={this.state.installment || ""} disabled={true} onChange={(event) => this.setState({ installment: event.target.value,isdisabled:true,rows:[] })} />
                            </div>
                          </div>
                        </div>
                        {/* <div className="textFieldStyle">
                          <h6 className="InputLabel Fonts SizeFont">{t('AddLoan.instal')}</h6>
                          <div style={{ height: '35px', border: this.state.changeColoremail4, display: "flex" }} className="textBox"><span className="aboutfield">{countryCurrencySymbol}</span>
                            <Input type="number" onClick={this.emailBox4} value={this.state.installment || ""} onChange={(event) => this.setState({ installment: event.target.value,isdisabled:true,rows:[] })} />
                          </div>
                        </div> */}
                        <div className="textFieldStyle" style={{ display: this.state.choosecustmr }}>
                          <h6 className="InputLabel Fonts SizeFont">{t('AddLoan.customer')}</h6>
                          <div style={{ height: '35px', border: this.state.changeColoremail5 }} className="textBox">
                            <Input list="browsers" className="textBox" placeholder="Choose Borrower" value={this.state.choosecustomer || ""} onClick={this.emailBox5} onChange={this.searchlist} disabled={this.props?.location?.params?.customerId ? true : false} onBlur={(event) => this.setState({searchdone:true})}/>
                          </div>
                          {this.state.allCustomer.length > 0 ? (
                            <div id="browsers" style={{ border: "1px solid lightgrey", borderRadius: "5px", display: this.state.afterSelect, width: "80%" }}>
                              {this.state.allCustomer.map((option, index) => (
                                <option key={option.firstName} value={option.firstName} className="Fonts" style={{ padding: "8px", cursor: "pointer" }} onClick={this.selectName.bind(this, option)}>{option.cust_id ? `${option.firstName} ${option.lastName} (${option.cust_id})` : `${option.firstName} ${option.lastName}`}</option>
                              ))}
                            </div>
                          ) : searchdone && <div className='errorMsg'>{`Choose a valid customer`}
                          <div id="browsers"
                          onBlur={(event) => this.setState({choosecustomer:""})}>                          
                          </div>
                          </div>} 
                        </div>
                        <div className="textFieldStyle Loan">
                          <h6 className="InputLabel Fonts SizeFont">{"Auto Update Non Paid Dues(EOD)"}</h6>
                          <Select
                            className="incomefield"
                            value={autoUpdateNPD}
                            style={{ border: this.state.annual, width: "80%", height: "35px", border: "1px solid rgb(212, 212, 213)", borderRadius: "5px" }}
                            onClick={this.annualBox}
                            disabled={whenNotPaid === "No" ? false : true}
                            onChange={(e) => this.setState({ autoUpdateNPD: e.target.value })}
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              Please Select
                            </MenuItem>
                            <MenuItem value="Yes">
                              Yes
                            </MenuItem>
                            <MenuItem value="No">
                              No
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "5%",
                        marginRight: "5%",
                        marginTop: "5%",
                      }}
                      className="rowColumn"
                    >
                      <div
                        className="rowColumn"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="savebutton btnSizeFont Fonts"
                          onClick={this.betweenRange}
                        >
                          {t("AddLoan.buttn1")}
                        </Button>
                        <Button
                          className="cancelbutton btnSizeFont Fonts"
                          onClick={this.clear.bind(this)}
                        >
                          {t("AddLoan.buttn2")}
                        </Button>
                      </div>
                      <div className="mt2">
                        <div className="TotalMoney">
                          <input placeholder={countryCurrencySymbol} value={this.state.totalAmount || ""} className="displaytotalmoney" disabled style={{ backgroundColor: "transparent" }} />
                        </div>
                        <span className="Fonts instalment">{t('AddLoan.circle')}</span>
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </div>
          ) : (
            <AddLoan
              onRef={(ref) => (this.child = ref)}
              collectionagent={this.state.collectionagent}
              TableData={this.TableData}
              isdisabled={this.state.isdisabled}
              customerId={this.props?.location?.params?.customerId}
              choosecustomer={this.props?.location?.params?.cust_id ? `${this.props?.location?.params?.firstName} ${this.props?.location?.params?.lastName} (${this.props?.location?.params?.cust_id})` : `${this.props?.location?.params?.firstName} ${this.props?.location?.params?.lastName}`}
            />
          )}
          {/* <div style={{ display: "flex", paddingBottom: "10px" }}>
            <div
              style={{
                paddingLeft: "10rem",
                paddingTop: "11px",
                width: "60.5%",
              }}
              className="Fonts"
            >
              {t("AddLoan.tablename")}
            </div>
          </div> */}
          <div className="cardDiv2">
            <div
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                
              }}
              className="Fonts"
            >
              {t("AddLoan.tablename")}
            </div>
            <div>
              <Table className='table'>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#E1F0FA", color: '#B2B2B2' }}>
                    <TableCell>{this.state.tenure}</TableCell>
                    <TableCell align="right">Due Date</TableCell>
                    <TableCell align="right">Opening Balance</TableCell>
                    <TableCell align="right">Installment</TableCell>
                    <TableCell align="right" style={{display:this.state.calInterest}}>Principal Amt.</TableCell>
                    <TableCell align="right" style={{display:this.state.calInterest}}>Interest</TableCell>
                    <TableCell align="right">Closing Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Month, Opening, Amt, Interest, Principal, Closing, Due */}
                  {this.state.rows && this.state.rows.map((row, index) => (
                    <TableRow key={index} className="Roww TextColour" style={{ marginBottom: '30px' }}>
                      <TableCell align="right" className="changetext">{index + 1}</TableCell>
                      <TableCell align="right" className="changetext">{moment(row.scheduledDate).format("DD MMM YYYY")}</TableCell>
                      <TableCell component="th" scope="row">
                        <div style={{ display: 'flex' }}>
                          <p style={{ paddingLeft: '10px', fontWeight: '800', color: '#3E4664' }} className="changetext"> {row.openingBalance} </p>
                        </div>
                      </TableCell>
                      <TableCell align="right" className="changetext" >{row.collectionAmount}</TableCell>
                      <TableCell align="right" className="changetext" style={{display:this.state.calInterest}}>{row.principal}</TableCell>
                      <TableCell align="right" className="changetext" style={{display:this.state.calInterest}}>{row.interest}</TableCell>
                      <TableCell align="right" className="changetext">{row.closingBalance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          {/* <div style={{display:"flex", marginLeft:"10.5rem", color: "#3E4664"}} className="Fonts">
          <div style={{width:"40.5%", color: "#3E4664"}} className="Fonts">Total</div>
          <div style={{width:"14%", color: "#3E4664"}} className="Fonts">66541551565</div>
          <div className="Fonts" style={{color: "#3E4664"}}>9884</div>
      </div> */}
          <Card className="cardDiv3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
               
                marginTop: "5%",
              }}
              className="rowColumn"
            >
              <div>
                <h6 className="Fonts assign">{t("AddLoan.agents")} </h6>
                <div>
                  <TextField
                    id="standard-select-currency"
                    select
                    className="field"
                    value={this.state.collectionagent}
                    fullWidth
                    style={{
                      border: this.state.annual,
                      border: "1px solid rgb(212, 212, 213)",
                      borderRadius: "5px",
                    }}
                    onClick={this.annualBox}
                    onChange={this.collectionangentChange.bind(this)}
                  >
                    {this.state.Collectors &&
                      this.state.Collectors.map((option) => (
                        <MenuItem key={option.userId} value={option.userId}>
                          {option.firstName} {option.lastName}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
              </div>
              <div className="mt2">
                <div
                  className="rowColumn"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="cancel btnSizeFont Fonts"
                    onClick={this.cancel.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ display: this.state.ButtonAction }}
                    className="save btnSizeFont Fonts"
                    onClick={this.postLoannoInterest.bind(this)}
                    disabled={this.state.isdisabled}
                  >
                    Save
                  </Button>
                  <Button
                    style={{ display: this.state.InterestButtonAction }}
                    className="save btnSizeFont Fonts"
                    onClick={this.postLoanInterest}
                    disabled={this.state.isdisabled}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            {/* <div>
              <h6 className="Fonts assign">{t("AddLoan.agents")} </h6>
              <div>
                <TextField
                  id="standard-select-currency"
                  select
                  className="field"
                  value={this.state.collectionagent}
                  style={{
                    border: this.state.annual,
                    width: "37%",
                    height: "35px",
                    border: "1px solid rgb(212, 212, 213)",
                    borderRadius: "5px",
                  }}
                  onClick={this.annualBox}
                  onChange={this.collectionangentChange.bind(this)}
                >
                  {this.state.Collectors &&
                    this.state.Collectors.map((option) => (
                      <MenuItem key={option.userId} value={option.userId}>
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
            <div style={{ textAlign: "end" }}>
              <Button
                className="cancel btnSizeFont Fonts"
                onClick={this.cancel.bind(this)}
              >
                Cancel
              </Button>
              <Button
                style={{ display: this.state.ButtonAction }}
                className="save btnSizeFont Fonts"
                onClick={this.postLoannoInterest.bind(this)}
                disabled={this.state.isdisabled}
              >
                Save
              </Button>
              <Button
                style={{ display: this.state.InterestButtonAction }}
                className="save btnSizeFont Fonts"
                onClick={this.postLoanInterest}
                disabled={this.state.isdisabled}
              >
                Save
              </Button>
            </div> */}
          </Card>
        </div>
      </div>
    );
  }
}
export default withNamespaces()(AddLoanWithoutInterest);