import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { BaseUrl } from "../../Environment";
import { Stack, Paper, Typography, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getCurrencySymbol, getSecureItem } from "../../utilsFunctions";
import Axios from "axios";

function PaymentForm({ priceData, modalValue }) {
  const userDetails = getSecureItem("loggedinUser");
  let countryCurrency = getSecureItem("companyCountry");

  const stripe = useStripe();
  const elements = useElements();

  const [btnLoading, setBtnLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateCompany = async (data) => {
    try {
      let loggedinUser = getSecureItem("loggedinUser");

      const { formattedDateStart, formattedDateEnd } = data;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getSecureItem("token"),
      };

      const companyData = {
        companyId: loggedinUser.companyId,
        planStartDate: formattedDateStart,
        planEndDate: formattedDateEnd,
        planId: priceData?.priceId,
        planPrice: priceData?.price,
        tenure: modalValue?.tenure,
        referedBy: modalValue?.companyIdReferedBy,
        trialActive: false
      };

      let formData = new FormData();
        formData.append('alldetails', JSON.stringify(companyData)); 

      const resp = await Axios.put(
        BaseUrl + "/company/putCompany",
        formData,
        {
          headers: headers,
        }
      );

      return resp;
    } catch (error) {
      console.log("error", error);
    }
  };

  const createSubscription = async () => {
    setBtnLoading(true);
    try {
      const cardElement = elements.getElement(CardNumberElement);

      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      // console.log("paymentMethod", paymentMethod);

      if (paymentMethod?.id) {
        const response = await fetch(`${BaseUrl}/subscribePayment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: `${userDetails?.firstName} ${userDetails?.lastName}`,
            email: userDetails?.userEmail,
            companyId: userDetails?.company?.companyId,
            paymentMethod: paymentMethod?.id,
            priceId: priceData?.priceId,
            price: priceData?.price,
            tenure: modalValue?.tenure,
            companyIdReferedBy: modalValue?.companyIdReferedBy
          }),
        });

        const data = await response.json();
        // console.log("subscribePayment", data);

        if (data?.clientSecret) {
          const confirm = await stripe.confirmCardPayment(data?.clientSecret);
          // console.log("confirm", confirm);

          if (confirm?.paymentIntent?.status === "succeeded") {
            const updateRes = updateCompany(data);
            // console.log("updateRes", updateRes);

            try {
              const response = await updateRes;
              const { status } = response.data;

              if (status) {
                setToastMsg({
                  msg: `Payment Successful! Subscription active.`,
                  time: 4000,
                  variant: "success",
                });

                setTimeout(() => {
                  handleOpen();
                }, 500);

                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }
            } catch (error) {
              console.error("Error:", error);
            }
          } else {
            setToastMsg({
              msg: confirm.error?.message,
              time: 5000,
              variant: "error",
            });

            setTimeout(() => {
              handleOpen();
            }, 500);
          }
        } else {
          setToastMsg({
            msg: data?.message,
            time: 5000,
            variant: "error",
          });

          setTimeout(() => {
            handleOpen();
          }, 500);
        }
      } else {
        setToastMsg({
          msg: "Invalid Payment Method",
          time: 4000,
          variant: "error",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
      }

      setBtnLoading(false);
    } catch (err) {
      console.log("err", err);
      setBtnLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>
      <div style={{ minWidth: "300px", width: "600px" }}>
        <Stack spacing={2}>
          <Stack spacing={0.5}>
            <Typography variant="h5" textAlign={"center"}>
              Let's finish powering you up!
            </Typography>
            <Typography variant="body2" fontSize={14} textAlign={"center"}>
              `{modalValue?.planName}` Plan is right for you
            </Typography>
          </Stack>

          <Paper style={{ paddingInline: 5 }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack justifyContent={"space-around"}>
                <Typography variant="body2" fontSize={16}>
                  Billed {modalValue?.tenure == "monthly" ? "Monthly" : "Annually"}
                </Typography>
                {modalValue?.companyIdReferedBy && <Typography variant="body1" color={"green"} fontSize={13}>Get {modalValue?.tenure == "monthly" ? "5 days" : "1 month"} extra
                </Typography>}
              </Stack>
              <Stack direction={"row"}>
                <sup style={{ fontSize: 12 }}>
                  {getCurrencySymbol(countryCurrency?.symbol)}
                </sup>
                <Typography variant="h4">
                  {modalValue?.discountedPrice}
                </Typography>
                <sub style={{ fontSize: 12, marginTop: "30px" }}>/{modalValue?.tenure == "monthly" ? "month" : "year"}</sub>
              </Stack>
            </Stack>
          </Paper>

          <Stack spacing={1} className="paymentElm">
            <Typography>CARD NUMBER</Typography>
            <CardNumberElement
              id="cardNumber"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    padding: "20px",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                },
              }}
            />
          </Stack>

          <Stack spacing={1} className="paymentElm">
            <Typography>EXPIRY DATE (MM/YY)</Typography>
            <CardExpiryElement
              id="cardExpiry"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                },
              }}
            />
          </Stack>

          <Stack spacing={1} className="paymentElm">
            <Typography>CVV/CVC</Typography>
            <CardCvcElement
              id="cardCvc"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                },
                placeholder: "CVV",
              }}
            />
          </Stack>

          <LoadingButton
            onClick={createSubscription}
            fullWidth
            variant="contained"
            sx={{ width: "200px", fontSize: "10px", color: "#fff" }}
            align="center"
            color="success"
            loading={btnLoading}
          >
            Upgrade my plan
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
}

export default PaymentForm;
