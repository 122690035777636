import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { getSecureItem } from "../../utilsFunctions";
import { ImageBaseUrl } from "../../Environment";

const BuyCreditsModal = ({ open, onClose, onSubmit }) => {
  const loggedinUser = getSecureItem("loggedinUser");
  const [selectedPlan, setSelectedPlan] = useState(true);
  const [whatsappCount, setWhatsappCount] = useState(1);
  const whatsappPrice = 200;
  const taxRate = 0.18;

  const subTotal = selectedPlan ? whatsappPrice * whatsappCount : 0;
  const tax = selectedPlan ? subTotal * taxRate : 0;
  const total = subTotal + tax;

  const GeneratePayLinkForSub = async () => {
    const PaymentLinkBaseURL = `${ImageBaseUrl}api/payment-links`;

    try {
      const response = await axios.post(
        `${PaymentLinkBaseURL}/create-payment-link`,
        {
          amount: total,
          borrowerEmail: loggedinUser?.userEmail,
          borrowerPhone: loggedinUser?.userPhone,
          linkTitle: "WhatsApp Credits",
          linkNotes: {
            companyId: loggedinUser?.companyId,
            credits: whatsappCount * 1000,
          },
          showReturnUrl: true,
        }
      );

      const { paymentLink } = response.data;
      if (paymentLink?.link_url) {
        onSubmit(paymentLink.link_url);
      }
    } catch (error) {
      console.error("Error generating payment link:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontFamily={"Lato"} variant="h6">Buy Credits</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={selectedPlan}
              onChange={() => setSelectedPlan(!selectedPlan)}
            />
          }
          label="WhatsApp Credits"
        />

        {selectedPlan && (
          <Box
            border={"1px solid #ddd"}
            borderRadius={3}
            padding={2}
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                size="small"
                style={{ backgroundColor: "#eee" }}
                onClick={() => setWhatsappCount(Math.max(1, whatsappCount - 1))}
              >
                <RemoveIcon />
              </IconButton>
              <Typography fontFamily={"Lato"}>{whatsappCount}</Typography>
              <IconButton
                size="small"
                style={{ backgroundColor: "#eee" }}
                onClick={() => setWhatsappCount(whatsappCount + 1)}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection={"column"} gap={0}>
              <Typography fontFamily={"Lato"}>
                ₹ {Number(whatsappPrice.toFixed(2)) * whatsappCount}
              </Typography>
              <Typography fontFamily={"Lato"}>
                {whatsappCount > 1 ? `(1000 x ${whatsappCount})` : `1000`}{" "}
                Credits
              </Typography>
            </Box>
          </Box>
        )}

        <Box mt={2} border={"1px solid #ddd"} borderRadius={3} padding={2}>
          <Typography fontFamily={"Lato"} variant="h6">Bill Details</Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontFamily={"Lato"}>Sub Total</Typography>
            <Typography fontFamily={"Lato"}>₹ {subTotal.toFixed(2)}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontFamily={"Lato"}>Taxes & Charges</Typography>
            <Typography fontFamily={"Lato"}>₹ {tax.toFixed(2)}</Typography>
          </Box>
          <Divider style={{ marginBlock: 5 }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontFamily={"Lato"} variant="h6">To Pay</Typography>
            <Typography fontFamily={"Lato"} variant="h6">₹ {total.toFixed(2)}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            bgcolor: "#4d66f0",
            borderRadius: 3,
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 16px",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography fontFamily={"Lato"} sx={{ color: "white", fontSize: "14px" }}>
              Total Value
            </Typography>
            <Typography fontFamily={"Lato"}
              sx={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
            >
              ₹ {total.toFixed(2)}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            sx={{
              bgcolor: "white",
              color: "#4d66f0",
              fontWeight: "bold",
              borderRadius: "8px",
              "&:hover": { bgcolor: "#e6e6e6" },
            }}
            size="medium"
            disabled={!selectedPlan}
            onClick={GeneratePayLinkForSub}
          >
            PAY NOW
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BuyCreditsModal;
