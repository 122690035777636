import moment from 'moment';
import React, { Component } from 'react';
import { fetchAPIData } from '../../service/service';
import Sidebar from '../sidebar/sidebar';
import CollectionCardView from './CollectionCardView/CollectionCardView';
import Header from './CollectionReportHeader/CollectionReportHeader';
import ReportTable from './ReportTable/ReportTable';
import LoadingPage from '../modals/LoadingPage/LoadingPage';
import { getSecureItem } from '../../utilsFunctions';





class CollectionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRecords: [],
      loanTypes: [],
      areaLineData: [],
      collectionAgents: [],
      filter: {},
      isLoading: false,
      collectionCardViewData: {
        loanCollectionAmount: 0,
        totalLoanCollectionAmount: 0,
        overDueAmount: 0,
        totalOverDueAmount: 0,
        paidCustomer: 0,
        totalCustomer: 0,
        penalty: 0
      }
    };
  }

  async componentDidMount() {

    this.setState({ isLoading: true });
    let result;

    //get type of loans
    result = await fetchAPIData("getLoanType", {})
    this.setState({ loanTypes: result.loanTypes });

    //get list of Collection Agents
    result = await fetchAPIData("collectionAgent", {})
    this.setState({ collectionAgents: result.collectionAgents });

    //Initial conditon is past 24 hours
    let last24Hrs = [moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")]
    this.filterEvent("dateFilter", last24Hrs)
    
  }


  filterEvent = async (key, value) => {
    let updatedFilter = this.state.filter
    let companyId = getSecureItem("loggedinUserCompany")
    updatedFilter.companyId = companyId
    this.setState({ isLoading: true });
    if (value) {
      switch (key) {
        case "loanTenureType":
          if (value === "all") {
            updatedFilter.hasOwnProperty("loanTenureType") && delete updatedFilter.loanTenureType
          } else {
            updatedFilter.loanTenureType = value
          }
          break;

        case "dateFilter":
          updatedFilter.fromDate = value[0]
          updatedFilter.toDate = value[1]
          break;

        case "filterType":

          if (!Array.isArray(value)) {
            if (value === "clearall") {
              delete updatedFilter.loanTypeId
              delete updatedFilter.loanAmountFrom
              delete updatedFilter.loanAmountTo
            }
          } else
            if (value[0] === "Loan Type")
              updatedFilter.loanTypeId = value[1]
            else {
              let range = value[1]
              let rangeArr = range.split("-")
              updatedFilter.loanAmountFrom = parseInt(rangeArr[0])
              updatedFilter.loanAmountTo = parseInt(rangeArr[1])
            }
          break;

        case "collectionAgent":
          value.length !== 0 ? updatedFilter.collectorId = value : delete updatedFilter.collectorId
          break;

        case "areaLineFilter":
        value.length !== 0 ? updatedFilter.areaLine = value : delete updatedFilter.areaLine
        break;

        default:
          console.log(`Invalid Key received Key: ${key} `)
          break;
      }
      this.setState({ filter: updatedFilter });

      let records = await fetchAPIData("tableRecord", updatedFilter)

      var filteredAL = records?.tableRecords?.filter((val) => val?.areaLine != null)?.map((val) => val?.areaLine);

      const calculateCardRecords = (records) => {
        let totalCustomer, paidCustomer, penalty, overDue, totalOverDue, loanCollectionAmount, totalLoanCollectionAmount, totalpenalty;
    
        totalCustomer = records?.length
        paidCustomer = records?.filter(record => record?.collectionStatus === "Paid")?.length
    
        penalty = 0;
        totalpenalty = records
            ?.filter(record => record?.penalty !== null)
            ?.reduce((penalty, record) => parseFloat(penalty) + parseInt(record?.penalty), 0)        
        overDue =0;
        totalOverDue = records?.filter(record => record?.loanPaymentStatus === "OverDue" && record?.collectionStatus === "Not Paid")
            ?.reduce((acc, record) => parseFloat(acc) + parseInt(record?.installment), 0)
    
        loanCollectionAmount = records.filter(record => record?.collectionStatus === "Paid")
            ?.reduce((acc, record) => parseFloat(acc) + parseInt(record?.collectedAmount), 0)
        totalLoanCollectionAmount = records
            ?.reduce((acc, record) => parseFloat(acc) + parseInt(record?.installment), 0)

        return {
            loanCollectionAmount: loanCollectionAmount,
            totalLoanCollectionAmount: totalLoanCollectionAmount,
            overDueAmount: overDue,
            totalOverDueAmount: totalOverDue,
            paidCustomer: paidCustomer,
            totalCustomer: totalCustomer,
            penalty: penalty,
            totalpenalty: totalpenalty
        }
    
      }

      this.setState({
        tableRecords: updatedFilter?.areaLine ? ( updatedFilter?.areaLine != "CLEARALL" ? records?.tableRecords?.filter((val) => val?.areaLine != null)?.filter((val) => val?.areaLine == updatedFilter?.areaLine) : records?.tableRecords ) : records?.tableRecords,
        areaLineData: [...new Set(filteredAL)],
        collectionCardViewData: updatedFilter?.areaLine ? ( updatedFilter?.areaLine != "CLEARALL" ? calculateCardRecords(records?.tableRecords?.filter((val) => val?.areaLine != null)?.filter((val) => val?.areaLine == updatedFilter?.areaLine)) : calculateCardRecords(records?.tableRecords) ) : calculateCardRecords(records?.tableRecords),
        isLoading: records.isLoading
      });


    } else {
      this.setState({ isLoading: false });
    };

  }

  render() {
    let headerCondition = {
      filterStatus: this.state.filter,
      filter: this.filterEvent,
      collectionAgents: this.state.collectionAgents,
      loanTypes: this.state.loanTypes,
      areaLineData: this.state.areaLineData
    }
    const { isLoading } = this.state

    return (
      <div>
        <LoadingPage isLoading={isLoading} />
        <Header condition={headerCondition} />
        <CollectionCardView data={this.state.collectionCardViewData} />
        <div>
          <ReportTable data={this.state.tableRecords} />
        </div>
        <Sidebar />

      </div>

    )
  }
}
export default CollectionReport;