import React, { useEffect, useState } from "react";
import {
  Stack,
  Switch,
  Paper,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
} from "@mui/material";
import Select from "react-select";
import Axios from "axios";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import { BaseUrl } from "../../Environment";
import { getSecureItem, setSecureItem } from "../../utilsFunctions";
import QRCode from "react-qr-code";
// import { generateQR, saveSession, logoutSession } from '../../service/service';
import { getCompanyProfile } from "../../api";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import BuyCreditsModal from "../ReminderSettings/BuyCreditsModal";

const Settings = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const loggedinUser = getSecureItem("loggedinUser");
  const [activeWhatsappSession, setActiveWhatsappSession] = useState(false);
  const [companyId] = useState(getSecureItem("loggedinUserCompany"));
  const [qrCode, setQrCode] = useState(null);
  const [qrCodeReGen, setQrCodeReGen] = useState(false);
  const [userType, setUserType] = useState();
  const [userTypes, setUserTypes] = useState([]);
  const [userTypeDetail, setUserTypeDetail] = useState({});
  const [initialUserTypeDetail, setInitialUserTypeDetail] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isDataChanged2, setIsDataChanged2] = useState(false);
  const [initialLoanSettings, setinitialLoanSettings] = useState({
    autoApprove: true,
    autoSms: false,
  });
  const [loanSettings, setLoanSettings] = useState({
    autoApprove: true,
    autoSms: false,
  });
  const [settingsId, setSettingsId] = useState("");

  const [whatsappSession, setWhatsappSession] = React.useState(false);

  const [id, setId] = useState(null);
  const [credits, setCredits] = useState(0);
  const [creditsModal, setCreditsModal] = useState(false);
  const [beforeDueDateEnabled, setBeforeDueDateEnabled] = useState(true);
  const [afterDueDateEnabled, setAfterDueDateEnabled] = useState(false);
  const [beforeDueDateOptions, setBeforeDueDateOptions] = useState([5]);
  const [afterDueDateOptions, setAfterDueDateOptions] = useState("Daily");

  const availableBeforeDays = [5, 3, 2, 1];

  const toggleSwitch = (setter) => setter((prev) => !prev);

  const removeBeforeDay = (day) => {
    const updatedDays = beforeDueDateOptions.filter((d) => d !== day);
    setBeforeDueDateOptions(updatedDays.length ? updatedDays : [5]);
  };

  const fetchData = async () => {
    try {
      const companyId = getSecureItem("loggedinUserCompany");
      const token = getSecureItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const resp = await Axios.get(
        `${BaseUrl}/autoReminders/getAutoReminders?companyId=${companyId}`,
        { headers }
      );

      if (resp.status === 200 && resp.data?.status) {
        const { id, afterDueDate, beforeDueDate, beforeDueDays, afterDueFreq, credits } = resp.data.data[0] || {};

        setId(id);
        setAfterDueDateEnabled(afterDueDate);
        setBeforeDueDateEnabled(beforeDueDate);
        setBeforeDueDateOptions(beforeDueDays?.length ? JSON.parse(beforeDueDays) : [5]);
        setAfterDueDateOptions(afterDueFreq || "Daily");
        setCredits(credits || 0);
      }
    } catch (error) {
      console.error("Error fetching reminders:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleOpenWhatsappSession = () => {
  //   handleGenerateQR()
  //   setWhatsappSession(true);
  // };

  const handleCloseWhatsappSession = () => {
    setWhatsappSession(false);
  };

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(
    loggedinUser?.userType === "superAdmin" ? "1" : "2"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  // Step 1: Initialize state with your array of options mapped to API keys
  const [options, setOptions] = useState([
    { label: "Add/Edit Borrowers", key: "addBorrowers", value: false },
    { label: "View Borrowers", key: "viewBorrowers", value: false },
    { label: "Add/Edit Loan", key: "addLoan", value: false },
    { label: "Add/Edit Loan Types", key: "addLoanTypes", value: false },
    { label: "View Loans", key: "viewLoan", value: false },
    { label: "Approve/Disburse Loan", key: "approveLoan", value: false },
    { label: "Revert Loan Payment", key: "revertPayment", value: false },
    { label: "Payment Collection", key: "receivePayment", value: false },
    { label: "View Reports", key: "viewReports", value: false },
    { label: "Print Documents", key: "printDocs", value: false },
    { label: "Expenses", key: "Expenses", value: false },
    { label: "Accounting", key: "accounting", value: false },
    { label: "Add/Edit User Management", key: "addUsers", value: false },
    { label: "Add/Edit Company Profile", key: "editProfile", value: false },
  ]);

  // Step 2: Function to handle the switch toggle
  const handleToggle = (index) => {
    // Update the userTypeDetail with the new value
    if (options[index].key === "viewBorrowers" && options[index].value) {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index
            ? { ...option, value: !option.value }
            : i === index - 1
            ? { ...option, value: false }
            : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
        [options[index - 1].key]: false,
      }));
    } else if (options[index].key === "viewLoan" && options[index].value) {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index
            ? { ...option, value: !option.value }
            : i === index - 2
            ? { ...option, value: false }
            : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
        [options[index - 2].key]: false,
      }));
    } else {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index ? { ...option, value: !option.value } : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
      }));
    }
  };

  const getAllUserTypes = async () => {
    try {
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.get(
        `${BaseUrl}/userType/getAllUserType?companyId=${loggedinUser.companyId}`,
        {
          headers,
        }
      );

      if (result.data.length > 0) {
        const saData = result?.data?.filter(
          (val) => val?.userType == "superAdmin"
        );
        setUserType(saData[0]?.userTypeId);
        setUserTypes(
          result?.data?.map((item) => ({
            label:
              item?.userType === "superAdmin" ? "Super Admin" : item.userType,
            value: item?.userTypeId,
          }))
        );
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  const getUserTypeDetails = async () => {
    try {
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.get(
        `${BaseUrl}/userType/getUserTypeDetails?userTypeId=${userType}&companyId=${loggedinUser?.companyId}&userId=${loggedinUser?.userId}`,
        {
          headers,
        }
      );

      // Update the userTypeDetail state with the data from the API
      setUserTypeDetail(result.data);
      setInitialUserTypeDetail(result.data);

      const saData = userTypes?.filter((val) => val?.label == "Super Admin");

      if (userType == saData[0]?.value) {
        setSecureItem(
          "UsersAccess",
          Object.fromEntries(
            Object.entries(result.data).filter(
              ([key, value]) => typeof value === "boolean"
            )
          )
        );
      }

      // Initialize the switch states based on the API data
      setOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          value: result.data[option.key] || false, // Initialize value based on API data
        }))
      );
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  // Check if any data has changed when the options or userTypeDetail state changes
  useEffect(() => {
    const isChanged =
      JSON.stringify(userTypeDetail) !== JSON.stringify(initialUserTypeDetail);
    setIsDataChanged(isChanged);
  }, [userTypeDetail, initialUserTypeDetail]);

  useEffect(() => {
    if (userType) {
      getUserTypeDetails();
    }
  }, [userType]);

  const updateUserType = async () => {
    try {
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.put(
        `${BaseUrl}/userType/putUserType`,
        userTypeDetail,
        {
          headers,
        }
      );

      if (result.data?.status) {
        setToastMsg({
          msg: `User Access Updated Successfully`,
          time: 4000,
          variant: "success",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);

        setIsDataChanged(false);
        getUserTypeDetails();
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  const getAllSettings = async () => {
    try {
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };
      // console.log(loggedinUser);
      const result = await Axios.get(
        `${BaseUrl}/settings/getAllSettings?companyId=${loggedinUser.companyId}&userId=${loggedinUser?.userId}`,
        {
          headers,
        }
      );

      if (result.data) {
        const loanSettings = result.data?.map((val) => ({
          autoApprove: val?.autoApprove,
          autoSms: val?.autoSms,
          settingsId: val?.settingsId
        }))[0];
        
        setSecureItem('LoanSettings', loanSettings); 
        
        setLoanSettings({
          autoApprove: result.data[0]?.autoApprove,
          autoSms: result.data[0]?.autoSms,
        });
        setinitialLoanSettings({
          autoApprove: result.data[0]?.autoApprove,
          autoSms: result.data[0]?.autoSms,
        });
        setSettingsId(result.data[0]?.settingsId);
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  // Fetch Company Data and Update Session
  const fetchCompanyData = async () => {
    try {
      const resp = await getCompanyProfile();
      if (resp) {
        setActiveWhatsappSession(resp.activeWhatsappSession);
      }
    } catch (err) {
      console.error("Error fetching company profile:", err);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    getAllSettings();
    getAllUserTypes();
  }, []);

  useEffect(() => {
    const isChanged =
      JSON.stringify(initialLoanSettings) !== JSON.stringify(loanSettings);
    setIsDataChanged2(isChanged);
  }, [loanSettings, initialLoanSettings]);

  // Handle switch toggle for loan settings
  const handleLoanSettingsToggle = (key) => {
    setLoanSettings((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    setIsDataChanged2(true);
  };

  // Update loan settings to the API
  const updateLoanSettings = async (logoutS) => {
    try {
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.put(
        `${BaseUrl}/settings/putSettings`,
        {
          settingsId,
          autoApprove: loanSettings.autoApprove ? "1" : "0",
          autoSms: logoutS ? false : loanSettings.autoSms,
        },
        {
          headers,
        }
      );

      if (result.data?.status) {
        setToastMsg({
          msg: `Loan Settings Updated Successfully`,
          time: 4000,
          variant: "success",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);

        setIsDataChanged2(false);
        getAllSettings();
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  // Generate QR Code
  // const handleGenerateQR = async () => {
  //   try {
  //     setQrCodeReGen(false);

  //     const response = await generateQR(companyId);
  //     if (response?.qr){
  //       setQrCode(response.qr);

  //       setTimeout(() => {
  //         setQrCodeReGen(true)
  //       }, 20000);
  //     };
  //   } catch (err) {
  //     console.error("Error generating QR code:", err);
  //   }
  // };

  // const handleSaveSession = async () => {
  //   const success = await saveSession(companyId);
  //   if (success) {
  //     handleLoanSettingsToggle("autoSms");
  //     fetchCompanyData();
  //     handleCloseWhatsappSession();
  //     // window.location.reload();
  //   };
  // };

  // Auto-save session every 10 seconds if no active session
  // useEffect(() => {
  //   if (qrCode && !activeWhatsappSession) {
  //     const interval = setInterval(() => handleSaveSession(), 5000);
  //     return () => clearInterval(interval);
  //   }
  // }, [activeWhatsappSession, qrCode]);

  // Logout Session
  // const handleLogout = async () => {
  //   try {
  //     const success = await logoutSession(companyId);
  //     console.log("Logout successful:", success);
  //     fetchCompanyData();
  //     if(success){
  //       updateLoanSettings(true);
  //       // window.location.reload();
  //     }
  //   } catch (err) {
  //     console.error("Error logging out:", err);
  //   }
  // };

  const updateReminders = async () => {
    try {
      const companyId = loggedinUser?.companyId;
      const token = getSecureItem("token");
      const headers = {
        Authorization: "Bearer " + token,
      };

      const payload = {
        companyId,
        id,
        credits,
        afterDueDate: afterDueDateEnabled,
        beforeDueDate: beforeDueDateEnabled,
        beforeDueDays: beforeDueDateEnabled
          ? JSON.stringify(beforeDueDateOptions)
          : null,
        afterDueFreq: afterDueDateEnabled ? afterDueDateOptions : null,
      };

      const resp = await Axios.put(
        BaseUrl + `/autoReminders/updateAutoReminders`,
        payload,
        {
          headers: headers,
        },
      );

      console.log('response updateReminders: ' + JSON.stringify(resp.data));
      if (resp.data?.status) {
        fetchData();
        setToastMsg({
          msg: resp.data?.msg,
          time: 4000,
          variant: "success",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
      }
    } catch (error) {
      console.log('error updateReminders', error);
    }
  };

  return (
    <>
      <Sidebar />

      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 100px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack
        className="otherincomepage"
        spacing={0}
        padding={"0px 20px 0px 100px"}
      >
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            {loggedinUser?.userType === "superAdmin" && (
              <Tab label="Loan Settings" value={"1"} />
            )}
            <Tab label="Access Settings" value={"2"} />
            <Tab label="Auto-Reminder Settings" value={"3"} />
          </Tabs>
        </Box>
        {value === "1" ? (
          <Paper
            elevation={3}
            sx={{ p: 2, width: matches ? "85%" : "50%", overflow: "auto" }}
            style={{ margin: "10px auto" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingInline={2}
              mb={2}
            >
              <Stack>
                <strong style={{ fontFamily: "Lato", fontSize: 15 }}>
                  Automatically Approve and Disburse Loans
                </strong>
                <em
                  style={{ fontFamily: "Lato", fontSize: 13, width: "300px" }}
                >
                  (When enabled, this will automatically change the loan status
                  to disbursed as soon as the loan is created)
                </em>
              </Stack>
              <Switch
                aria-label={"autoApprove"}
                checked={loanSettings.autoApprove}
                onChange={() => handleLoanSettingsToggle("autoApprove")}
              />
            </Stack>
            {/* <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingInline={2}
            >
              <strong style={{ fontFamily: "Lato", fontSize: 15 }}>
              Auto-send transaction messages via WhatsApp login.{" "}
                <button style={{backgroundColor: activeWhatsappSession ? "#fc4040" : "#4d66f0", color: "white", fontWeight: "bold", fontFamily: "Lato", padding: "3px 15px 5px 15px", margin: 5, borderRadius: 50, borderColor: "transparent", cursor: "pointer"}} onClick={activeWhatsappSession ? handleLogout : handleOpenWhatsappSession }>
                  {activeWhatsappSession ? "Logout" : "Login"}
                </button>
              </strong>
              <Switch
                aria-label={"autoSms"}
                checked={loanSettings.autoSms}
                onChange={() => activeWhatsappSession ? handleLoanSettingsToggle("autoSms") : handleOpenWhatsappSession()}
              />
            </Stack> */}
          </Paper>
        ) : value === "2" ? (
          <Paper
            elevation={3}
            sx={{ p: 2, width: matches ? "85%" : "50%", overflow: "auto" }}
            style={{ margin: "10px auto" }}
          >
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack spacing={0.5}>
                <strong style={{ fontFamily: "Lato", fontSize: 14 }}>
                  Choose User Type
                </strong>
                <Select
                  options={userTypes}
                  placeholder="Select User's Type"
                  value={userTypes?.find((item) => item.value === userType)}
                  onChange={(val) => setUserType(val?.value)}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "250px",
                      fontFamily: "Lato",
                    }),
                  }}
                />
              </Stack>
            </div>
            {options?.map((data, index) => (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingInline={2}
                key={index}
              >
                <strong style={{ fontFamily: "Lato", fontSize: 15 }}>
                  {data?.label}
                </strong>
                <Switch
                  aria-label={data?.label}
                  checked={data?.value}
                  onChange={() => handleToggle(index)}
                  disabled={
                    // Disable "addBorrowers" if "viewBorrowers" is false
                    (data.key === "addBorrowers" &&
                      !options.find((o) => o.key === "viewBorrowers").value) ||
                    // Disable "addLoan" if "viewLoan" is false
                    (data.key === "addLoan" &&
                      !options.find((o) => o.key === "viewLoan").value)
                  }
                />
              </Stack>
            ))}
          </Paper>
        ) : (
          <Paper
            elevation={3}
            sx={{ p: 2, width: matches ? "85%" : "50%", overflow: "auto" }}
            style={{ margin: "10px auto" }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography>WhatsApp Credits: {credits} left</Typography>
              <Button variant="contained" color="success" onClick={() => setCreditsModal(true)}>
                Buy More
              </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography>After Due Date</Typography>
              <Switch checked={afterDueDateEnabled} onChange={() => toggleSwitch(setAfterDueDateEnabled)} />
            </Box>
            {afterDueDateEnabled && (
              <Select
                options={[{label: "Daily", value: "Daily"}, {label: "Weekly", value: "Weekly"}]}
                value={[{label: "Daily", value: "Daily"}, {label: "Weekly", value: "Weekly"}].find((item) => item.value == afterDueDateOptions)}
                onChange={(val) => setAfterDueDateOptions(val?.value)}
              />
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography>Before Due Date</Typography>
              <Switch checked={beforeDueDateEnabled} onChange={() => toggleSwitch(setBeforeDueDateEnabled)} />
            </Box>
            {beforeDueDateEnabled && (
              <Box mt={2}>
                {beforeDueDateOptions.map(day => (
                  <Box key={day} display="flex" alignItems="center" justifyContent="space-between" p={1} sx={{ backgroundColor: '#e0e0e0', borderRadius: 1, mb: 1 }}>
                    <Typography>{day} {day === 1 ? "day" : "days"}</Typography>
                    <IconButton onClick={() => removeBeforeDay(day)} size="small" color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                {availableBeforeDays.filter(day => !beforeDueDateOptions.includes(day))?.length != 0 && <Select
                  options={availableBeforeDays.filter(day => !beforeDueDateOptions.includes(day)).map(day => (
                    {value: day, label: `${day} ${day === 1 ? "day" : "days"}`}
                  ))}
                  onChange={val => setBeforeDueDateOptions([...beforeDueDateOptions, val.value])}
                />}
              </Box>
            )}
            <BuyCreditsModal open={creditsModal} onClose={() => setCreditsModal(false)} onSubmit={(link) => window.open(link, "_blank")} />
          </Paper>
        )}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          style={{ margin: "10px auto", width: matches ? "85%" : "50%" }}
        >
          {value === "1" ? (
            <Button
              className="GreenBTN"
              style={{ width: "100px" }}
              variant="filled"
              onClick={() => updateLoanSettings(false)}
              disabled={!isDataChanged2}
            >
              Save
            </Button>
          ) : value === "2" ? (
            <Button
              className="GreenBTN"
              style={{ width: "100px" }}
              variant="filled"
              onClick={updateUserType}
              disabled={!isDataChanged}
            >
              Save
            </Button>
          ) : (
            <Button
              className="GreenBTN"
              style={{ width: "100px" }}
              variant="filled"
              onClick={updateReminders}
            >
              Save
            </Button>
          )}
        </Stack>
      </Stack>
      <Dialog
        open={whatsappSession}
        onClose={handleCloseWhatsappSession}
        aria-labelledby="alert-dialog-title-support"
        aria-describedby="alert-dialog-description-support"
      >
        <DialogTitle textAlign={"center"}>How To Proceed</DialogTitle>
        <Stack
          sx={{ padding: 2 }}
          spacing={1}
          direction={"row"}
        >
          <ol style={{padding: 0, margin: "0px 20px"}}>
            <li>Open WhatsApp on your phone</li>
            <li>Tap Menu (⋮) or Settings (⚙️) and go to Linked Devices</li>
            <li>Tap on Link a Device</li>
            <li>Point your phone at this QR code to scan and link</li>
            <li>Please stay on this page until you have successfully logged in</li>
          </ol>
          <Divider orientation="vertical" flexItem sx={{ mx: 2}} />
          {qrCode ? <QRCode style={{paddingInline: 10}} size={100} value={qrCode} /> : 
          <Stack justifyContent={"center"} alignItems={"center"}>
            <LoadingButton loading={true} />
            <Typography style={{fontFamily: "Lato", textAlign: "center"}}>Generating your QR code... Please wait a moment!</Typography>
          </Stack>}
        </Stack>
        {/* {qrCodeReGen && <DialogActions><Button onClick={handleGenerateQR}>Regenerate QR Code</Button></DialogActions>} */}
      </Dialog>
    </>
  );
};

export default Settings;
