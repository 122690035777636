import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlanExp from "../../assets/images/planexp.png";


const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#4d66f0",
});

const StyledCard = styled(Card)({
  width: 400,
  textAlign: "center",
  padding: "20px",
  borderRadius: "10px",
});

const IconContainer = styled(Box)({
  fontSize: "60px",
  color: "#4d66f0",
  display: "flex",
  justifyContent: "center",
  marginBottom: "10px",
});

export default function PlanEnded() {
  const history = useHistory();

  const handleRefresh = () => {
    history.push("/pricing");
  };

  return (
    <Container>
      <StyledCard>
        <CardContent>
          <IconContainer>
            <img src={PlanExp} width={"150px"} height={"120px"} alt="Plan Expired" />
          </IconContainer>
          <Typography style={{fontFamily: "Lato"}} variant="h6" fontWeight="bold">
            Your subscription has expired
          </Typography>
          <Typography style={{fontFamily: "Lato"}} variant="body2" marginY={1}>
            Renew now to continue using LendStack. Don't worry,Your data is safe and will be restored upon reactivation.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefresh}
            sx={{ marginTop: "10px", textTransform: "none", fontFamily: "Lato" }}
          >
            Renew Now
          </Button>
        </CardContent>
      </StyledCard>
    </Container>
  );
}
