import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import editpencil from '../../../assets/images/editpencil.svg';
import buildingline from '../../../assets/images/buildingline.svg';
import administratorline from '../../../assets/images/administratorline.svg';
import userdefault from '../../../assets/images/userdefault.svg';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import '../profile modal/profileModal.scss';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BaseUrl,ImageBaseUrl} from "../../../Environment"
import { withNamespaces } from 'react-i18next';
import { auth } from '../../../firebase/config';
import { getSecureItem, removeSecureItem, trialDaysRemaining, trialEndDateCalc } from '../../../utilsFunctions';
import moment from 'moment';

const axios = require('axios')

class ProfileModal extends Component {

    constructor() {
        super();
        this.state = {
            userTextcolor: "",
            companyTextcolor: "",
            GettingloginToken: "",
            loginToken: getSecureItem("token"),
            loggedinUserDetails: [],
            adminaccess: "",
        }
        this.closeprofilemodal = this.closeprofilemodal.bind(this);
        this.userManagement = this.userManagement.bind(this);
    }
    closeprofilemodal() {
        this.props.close()
    }
    userManagement() {
        this.setState({
            userTextcolor: "#00D95E",
            companyTextcolor: "#3E4664"
        })
        this.props.history.push('/usermanagement')
    }
    editadminprofile() {

        this.props.history.push({ pathname: '/updateadmin', params: this.state.loggedinUserDetails })
    }
    companyprofile() {
        this.setState({
            companyTextcolor: "#00D95E",
            userTextcolor: "#3E4664",
        })
            this.props.history.push('/companyprofile')
    }
    GetData() {
        let details = getSecureItem("loggedinUser")
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ getSecureItem("token")
          }
        axios.get(BaseUrl + '/user/getUserDetails?userId=' + details.userId, {
            headers: headers,
        }).then(resp => {
            if (resp.status === 200) {
                this.setState({
                    loggedinUserDetails: resp.data,
                })
                if (resp.data.userType === "Admin" || resp.data.userType === "superAdmin") {
                    this.setState({
                        adminaccess: ""
                    })
                } else {
                    this.setState({
                        adminaccess: "none"
                    })
                }            } else {
                toast.error("Please try again later!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            }
        })
    }
    componentDidMount() {
        if(getSecureItem("token")){
        this.GetData() 
    }else {
            this.props.history.push("/login")
          }      
    }

    logout() {
        this.setState({
            GettingloginToken: removeSecureItem('token')
        })
        removeSecureItem('loggedinUser')
        removeSecureItem('userid')
        removeSecureItem('loggedinUserCompany')
        localStorage.clear();
        auth.signOut();
        this.props.history.push('/')
    }

    endTrialPlan = async () => {
        let companyId = getSecureItem('loggedinUserCompany');
        let token = getSecureItem("token");

        try {
            let data = new FormData();

            data.append("companyId", companyId);
            data.append("trialActive", "false");
        
            await axios({
            url: `${BaseUrl}/company/putMobileCompany`,
            method: "POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
            },
            });
        } catch (error) {
            console.log({error});
        }
    };

    render() {
        const { t } = this.props;

        let loggedinUser = getSecureItem("loggedinUser")

        const trialActive = loggedinUser?.company?.trialActive;
        const trialDays = trialDaysRemaining(loggedinUser?.company?.createdAt)
        const planId = loggedinUser?.company?.planId;
        const planEndDate = loggedinUser?.company?.planEndDate;
        const trialEndDate = trialEndDateCalc(loggedinUser?.company?.createdAt)

        const priceData = [
            {color: '#000000', planName: "Free"},
            {color: '#0F27AE', planName: "Starter"},
            {color: '#620068', planName: "Business"},
            {color: '#066800', planName: "Unlimited"},
        ];

        if(trialActive && trialDays <= 0){
        this.endTrialPlan();
        }

        return (
            <div onClick={this.closeprofilemodal}>
                <Dialog open={this.props.open} className="mainDiv">
                    <DialogTitle id="customized-dialog-title" className="TitleDiv">
                        <div style={{ display: 'flex' }}>
                            <Grid item xs={4}>
                                <CloseIcon style={{ cursor: 'pointer' }} onClick={this.closeprofilemodal} />
                            </Grid>
                            <Grid item xs={3} className="profileimagepart">
                                <div style={{ width: "80px", height: "80px", borderRadius: "50%", border:"1px solid lightgrey" }}>
                                {this.state.loggedinUserDetails && <img src={this.state.loggedinUserDetails.userImagePath ? ImageBaseUrl + this.state.loggedinUserDetails.userImagePath : userdefault} alt="profilepic" style={this.state.loggedinUserDetails.userImagePath ?{ width: "100%",height: "100%",borderRadius: "50%"}:{ width: "55px", paddingTop:"4px"}} />}
                            </div>
                            </Grid>
                            <Grid item xs={4} className="editimagepart">
                                <img src={editpencil} alt="profilepic" onClick={this.editadminprofile.bind(this)} />
                            </Grid>
                        </div>
                        <div className="ProfilePersonNameDiv">
                        {this.state.loggedinUserDetails && <h5 className="ProfilePersonName">{this.state.loggedinUserDetails.firstName} {this.state.loggedinUserDetails.lastName}</h5>}
                        {this.state.loggedinUserDetails && <h6 className="languagetext">{this.state.loggedinUserDetails.language || "English"}</h6>}
                        {trialActive && <h6 className="languagetext" style={{fontWeight: "bold"}}>Trial <span style={{color: "red"}}>({trialDays} Days Left)</span></h6>}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Grid item xs={7} className="emailcontainer">
                            {this.state.loggedinUserDetails && <h6 className="emailtext">{this.state.loggedinUserDetails.userEmail}</h6>}
                            {this.state.loggedinUserDetails && <h6 className="phonetext">{this.state.loggedinUserDetails.userPhone}</h6>}
                            </Grid>
                            <Grid item xs={4} className="signoutbuttonDiv">
                                <Button className="Fonts SizeFont btnbackgroundcolor signoutbutton" onClick={this.logout.bind(this)}>{t('ProfileModal.signout')}</Button>
                            </Grid>
                        </div>
                        {planEndDate && (
                        <Stack
                            sx={{
                            boxShadow: 5,
                            border: '0.5px solid #bbb',
                            bgcolor: '#eee',
                            borderRadius: 2,
                            p: 2,
                            mt: 2,
                            mr: 2
                            }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Active Plan
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ 
                                bgcolor: priceData[Number(planId)]?.color,
                                color: 'white'
                                }}
                                label={priceData[Number(planId)]?.planName}
                            />
                            </Stack>
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Expires On
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ bgcolor: 'grey', color: 'white' }}
                                label={moment(planEndDate).format('DD/MM/YYYY')}
                            />
                            </Stack>
                        </Stack>
                        )}

                        {trialActive && trialEndDate && (
                        <Stack
                        sx={{
                            boxShadow: 5,
                            border: '0.5px solid #bbb',
                            bgcolor: '#eee',
                            borderRadius: 2,
                            p: 2,
                            mt: 2,
                            mr: 2
                            }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Active Plan
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ bgcolor: '#066800', color: 'white' }}
                                label="Trial (Unlimited)"
                            />
                            </Stack>
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Expires On
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ bgcolor: 'grey', color: 'white' }}
                                label={moment(trialEndDate).format('DD/MM/YYYY')}
                            />
                            </Stack>
                        </Stack>
                        )}

                        {!trialActive && planId == "0" && (
                        <Stack
                        sx={{
                            boxShadow: 5,
                            border: '0.5px solid #bbb',
                            bgcolor: '#eee',
                            borderRadius: 2,
                            p: 2,
                            mt: 2,
                            mr: 2
                            }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Active Plan
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ bgcolor: '#066800', color: 'white' }}
                                label="Free"
                            />
                            </Stack>
                            <Stack spacing={1} alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                                Expires On
                            </Typography>
                            <Chip
                                variant="filled"
                                sx={{ bgcolor: 'grey', color: 'white' }}
                                label={moment().add(30, 'years')?.format('DD/MM/YYYY')}
                            />
                            </Stack>
                        </Stack>
                        )}
                    </DialogTitle>
                    <DialogContent className="bottomPart">
                        <div style={{ display: this.state.adminaccess }}>
                            <div className="companyprofile Fonts">
                                <img src={buildingline} alt="buildingImage" />
                                <h6 className="companyprofiletext" style={{ color: this.state.companyTextcolor }} onClick={this.companyprofile.bind(this)}>{t('ProfileModal.Company')}</h6>
                            </div>
                            <div className="usermanagement Fonts" onClick={this.userManagement}>
                                <img src={administratorline} alt="buildingImage" />
                                <h6 className="usermanagementtext" style={{ color: this.state.userTextcolor }}>{t('ProfileModal.Management')}</h6>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                
            </div>
        )
    }
}
export default withNamespaces()(withRouter(ProfileModal));